:root {
    --darkblue: #140939;
    --lightblue: #f8f6fe;
    --gray-text: #b4b4b4;
}

body {
    background-color: white;
    margin: 0;
}

/* header */

.bg-sa-dark-blue {
    background-color: var(--darkblue);
}

.sa-h-f-size {
    font-size: 20px;
}

.header__logo {
    align-items: center;
    display: flex;
    gap: 16px;
}

.logo {
    height: 40px;
    object-fit: cover;
    width: 30px;
}

.logo__text {
    color: white;
    font-size: 20px;
}

/* main */

.main {
    background-color: white;
}

.address-form {
    background-color: var(--lightblue);
    border-radius: 16px;
    padding: 2em;
}

.address__search {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2em 0.5em;
}

.address__search--box {
    margin: 2em 1em;
}

.address__search--heading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1em;
}

.address__search--text {
    font-size: 16px;
    max-width: 370px;
}

.search-box {
    background-color: var(--lightblue);
    border-radius: 30px;
    padding: 25px;
    width: 100%;
}

.search-box--input {
    background-color: white;
    border: 1px solid var(--darkblue);
    border-radius: 8px;
    color: var(--gray-text);
    max-width: 450px;
    overflow: hidden;
    padding: 1em;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
}

.search-box--search {
    display: flex;
    justify-content: center;
}

.search__span {
    background-color: var(--darkblue);
    border-radius: 16px;
    color: white;
    padding: 1em;
    text-align: center;
    width: fit-content;
}

.search-box--search:hover {
    color: var(--lightblue);
}

.advantages {
    margin-bottom: 60px;
    text-align: center;
}

.advantages--heading {
    font-size: 20px;
    font-weight: 400;
    margin: 2em 0.75em;
}

.advantages__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: center;
    padding: 0.5em;
}

.advantages__block {
    align-items: center;
    background-color: var(--lightblue);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-width: 380px;
    padding: 28px 16px;
    text-align: center;
}

.advantages__block--img {
    object-fit: cover;
}

.advantages__block--text {
    color: var(--darkblue);
    font-size: 18px;
    line-height: 35px;
    margin: 0;
    padding-top: 16px;
}

/* ADDRESS */

.address__h2 {
    text-align: center;
}

.sa-text-bold {
    font-weight: bold;
}

.sa-box {
    background-color: var(--lightblue);
}

.sa-map-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 1em 0;
}

.sa-maps-btn {
    background-color: #433a61;
    border-radius: 12px;
    color: white;
    margin: 0.25em;
    padding: 0.5em;

    &:hover {
        background-color: var(--darkblue);
    }
}

/* footer */

.footer {
    background-color: var(--darkblue);
    display: flex;
    flex-direction: column;
}

.footer__wrapper {
    padding: 20px;
}

.footer__h3 {
    font-weight: bold;
    margin-top: 10px;
}

.footer__logo {
    align-items: center;
    display: flex;
    gap: 16px;
    margin-bottom: 60px;
}

.footer__contact--wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
}

.footer__block {
    color: white;
    display: block;
}

.footer__block--text {
    margin-top: 32px;
}

.footer__icon {
    align-items: center;
    color: white;
    display: flex;
    gap: 12px;
    margin: 16px 0;

    &:hover {
        text-decoration: underline;
    }
}

.footer__link {
    color: white;
    display: block;
    margin: 16px 0;

    &:hover {
        text-decoration: underline;
    }
}

.footer__line {
    border: 0.5px solid white;
    display: flex;
    height: 0.5px;
    margin: 0 1em;
}

.footer__text {
    display: block;
    gap: 16px;
    padding: 2em 0;
    text-align: center;
}

.footer__copy--text {
    color: white;
    margin: 0;
    padding: 0.5em;
    text-align: center;
}

@screen md {
    .address-form {
        transform: scale(1.2);
    }

    .address__search {
        display: flex;
        justify-content: space-between;
    }

    .address__search--heading {
        font-size: 28px;
    }

    .address__search--text {
        font-size: 18px;
    }

    .search-box {
        justify-content: center;
        max-width: 480px;
    }

    .advantages--heading {
        font-size: 24px;
    }

    .footer__wrapper {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-top: 2em;
    }

    .footer__line {
        border: 0.5px solid white;
        display: flex;
        height: 0.5px;
        margin: 0;
    }

    .footer__text {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@screen xl {
    .advantages__wrapper {
        display: flex;
        flex-wrap: nowrap;
        gap: 48px;
    }
}
